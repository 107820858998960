import axios from "axios"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { FieldValues, useForm } from "react-hook-form"
import toast, { Toaster } from "react-hot-toast"
import Layout from "../components/Layout"
import MobileWrapper from "../components/MobileWrapper"
import { Content, Form, FormGroup, H1, Input, Link, P, Submit, Textarea } from "../styles"

const ContactoPage = () => {
  const { handleSubmit, register, reset } = useForm();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (values: FieldValues) => {
    try {
      setLoading(true);
      const { message, name, email } = values;

      await axios
        .post("https://getform.io/f/33da9708-6dc2-4afe-9be6-1cb8e21ca49e", {
            name,
            email,
            message,
        }, 
        { headers: {'Accept': 'application/json'}});

      toast.success('Tú consulta fue enviada con éxito! 🙌')
      setLoading(false);
      reset({
        message: '',
        name: '',
        email: '',
      });
    } catch (err) {
      toast.error('No pudimos enviar tu consulta! 😞')
      setLoading(false);
    }
  }

  return (
    <Layout>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <MobileWrapper>
        <StaticImage
          placeholder="blurred"
          src="../images/contact.png"
          alt="home image"
          style={{ maxWidth: '50%', height: 'auto', position: 'absolute', right: 96 }}
        />
      </MobileWrapper>
      <Content>
        <H1 animation>
          Contacto
        </H1>
        <P animation>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <label>Nombre</label>
              <Input {...register("name", { required: true })} name="name" type="text" placeholder="Nombre Apellido" />
            </FormGroup>
            <FormGroup>
              <label>Email</label>
              <Input {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} name="email" type="text" placeholder="tu@mail.com" />
            </FormGroup>
            <FormGroup>
              <label>Consulta</label>
              <Textarea {...register("message", { required: true })} name="message" placeholder="Quería consultar ..."/>
            </FormGroup>
            <input type="hidden" name="_gotcha" style={{ display: 'none !important' }} />

            <Submit disabled={loading} type="submit" value="Enviar" />
          </Form>
        </P>
        <p>También podés hacer tu consultas por <Link href="mailto:remediosmsanchez@gmail.com" target="_blank">acá</Link> o whatsapp <Link href="https://api.whatsapp.com/send/?phone=5493513347389" target="_blank">acá</Link></p>
      </Content>
    </Layout>
  )
}

export default ContactoPage
